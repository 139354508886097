// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.height-100-pct {
  height: 100%;
}

.padding-top-100px {
  padding-top: 100px;
}

.button-start {
  font-size: 26px;
  width: 270px;
}

.setup-account-layout {
  background-image: url('/img/background-setup.png');
  height: 100%;
}

.setup-account-header {
  font-weight: 900;
  color: #3c7fea;
}

.setup-account-body {
  text-align: left;
  background: white;
  border: black 3px solid;
  padding: 10px 10px 10px 10px;
}
.setup-account-body > * {
  font-size: 14pt;
}

.setup-account-form-header {
  color: #f57648;
  font-weight: 900;
  font-size: 22px;
}

.mobile-number-validator {
  min-width: 215px;
}

.staff-row {
  margin-bottom: 10px;
}

.info-account-email-confirmation {
  font-size: 20pt;
  font-weight: 900;
}

.bold {
  font-weight: 900;
}

.large-modal {
  width: 100%;
}

.datapicker-modal {
  margin-top: 27px;
}

.dashboard-todo-clear-completed {
  float: right !important;
  margin-right: 10px;
  margin-bottom: 3px;
}

.dashboard-todo-item-description {
  text-align: left;
}

.dashboard-todo-item-actions {
  text-align: right;
}

.dashboard-todo-item-checked {
  text-decoration: line-through;
}

div.dashboard-todo-form-item-checkbox {
  padding-left: 30px;
  padding-right: 0;
  padding-top: 3px;
}

div.dashboard-todo-form-item {
  margin-bottom: 10px;
}

input.date {
  text-align: center;
}

.link {
  cursor: pointer;
}

canvas {
  width: 75%;
}

.list-exception-dates {
  max-height: 288px;
  height: 288px;
  overflow-y: scroll;
  text-align: center;
}

.full-width {
  width: 100% !important;
}

span.alphabet-search:not(:first-child){
  content: '|';
  margin-left: 3px;
}

.alphabet-search {
  font-weight: 600;
  font-size: 18px;
}

.alphabet-search.active {
  font-weight: 900;
  color: #5bc0de;
}

.connecting-line-tree {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 65%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
}

.wizard .nav-tabs > li.tree {
  width: 33.33%;
}

canvas {
  max-height: 300px;
}

#subject-chart {
  max-height: 700px;
}

