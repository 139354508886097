/*------------------------------------------------------------------
Teachers In Touch
------------------------------------------------------------------*/

/** Base Body Styles **/
body {
  background: url(../../img/body-bg.png);
  color: #838383;
  font: 13px/1.7em 'Open Sans';
}

.account-container {
  width: 380px;
  display: block;
  margin: 60px auto 0 auto;

  background: #f9f9f9;

  border: 1px solid #d5d5d5;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  box-shadow: 0px 0px 2px #dadada, inset 0px -3px 0px #e6e6e6;
}

.content {
  padding: 16px 28px 23px;
}

.login-fields {

}

.login-fields .field {
  margin-bottom: 1.25em;
}

.login-fields label {
  display: none;
}

.login-actions button, .login-fields input {
  width: 100%;
}



.username-field {
  background: url(../../img/signin/user.png) no-repeat;
}

.password-field {
  background: url(../../img/signin/password.png) no-repeat;
}

.login-actions {
  float: left;

  width: 100%;

  margin-top: -1em;
  margin-bottom: 1.25em;
}

.login-social {
  float: left;

  padding: 10px 0 15px;

  border: 1px dotted #CCC;
  border-right: none;
  border-left: none;
}

span.login-checkbox {
  float: left;
  margin-top: 31px;
}

span.login-checkbox > input[type='checkbox'] {
  opacity: 0;
  float: left;
  width: 15px;
}

span.login-checkbox > input[type='checkbox'] + label {
  clear: none;

  height: 15px;
  display: block;
  padding: 0 0 0 22px;
  margin: 0;

  font-size: 12px;
  line-height: 1.2em;

  background: url(../../img/signin/check.png) no-repeat 0 0;

  cursor: pointer;
}

span.login-checkbox > input[type='checkbox']:checked + label {
  background-position: 0 -15px;
}

/** Text Under Box**/
.login-extra {
  display: block;
  width: 300px;
  margin: 1.5em auto;

  text-align: left;
  line-height: 19px;

  text-shadow: 1px 1px 0px #fff;
}

.account-container h1 {
  margin-bottom: .4em;

  color: #525252;
}

/** Buttons **/
.twitter, .fb {
  position: relative;

  height: 32px;
  width: 157px;
  display: block;

  background: url(../../img/signin/twitter_btn.png) no-repeat;

}

.fb {
  width: 162px;

  background: url(../../img/signin/fb_btn.png) no-repeat;
}

.twitter:active, .fb:active {
  top: 1px;
}

.twitter:hover, .fb:hover {
  background-position: 0 -32px;
}

.twitter a, .fb a {
  padding: 5px 0 0 35px;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, .3);
  color: #fff;
  font-weight: bold;
  font-size: 11px;
  height: 32px;
  display: block;
}

.fb a {
  padding: 5px 0 0 31px;

}

.twitter, .fb {
  display: inline-block;
}

.twitter a:hover, .fb a:hover {
  color: #FFF;
  text-decoration: none;
}

.button {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  float: right;
  margin-top: 18px;
}

.register .login-social {
  margin-bottom: 1em;
}

.register .login-actions {
  margin-bottom: 0;
}

.account-container .checkbox {
  margin-left:20px;
}

.register .login-fields input {
  width: 299px;
  padding-left: 6px;
}

.register h1 {
  color: #444;
}

.register span.login-checkbox {
  position: relative;
  top: -6px;

  width: 200px;
}

.register span.login-checkbox > input[type="checkbox"] + label {

  position: relative;

  line-height: 1.3em;
}

@media (max-width: 480px) {

  .account-container {
    width: 280px;
    margin-top: 35px;
  }

  .login-fields input {
    width: 160px;
  }

  .login-social {
    width: 100%;
  }

  .twitter {
    display: block;
    margin-bottom: 1em;
  }

  .register .login-fields input {
    width: 204px;
    padding-left: 6px;
  }

}